import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-scroll';
import { Link as GatsbyLink } from 'gatsby';

const Header = ({ navItems }) => {
  const [mobileMenu, setMobileMenu] = useState(false);

  return (
    <header className={mobileMenu ? 'header header--opened' : 'header'}>
      <div
        className="header__toggle"
        onClick={() => setMobileMenu(!mobileMenu)}
      >
        <svg
          className="header__toggle-open"
          width="31"
          height="22"
          viewBox="0 0 31 22"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(-40 -19)" fill="#FFF">
              <g transform="translate(40 19)">
                <g>
                  <rect width="31" height="4" />
                </g>
                <g transform="translate(0 9)">
                  <rect width="31" height="4" />
                </g>
                <g transform="translate(0 18)">
                  <rect width="31" height="4" />
                </g>
              </g>
            </g>
          </g>
        </svg>
        <svg
          className="header__toggle-close"
          width="31"
          height="31"
          viewBox="0 0 31 31"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(-40 -14)" fill="#FFF">
              <g transform="translate(40 14)">
                <g transform="rotate(-45 33.825 13.79)">
                  <rect width="38.44" height="4" />
                </g>
                <g transform="scale(-1 1) rotate(-45 18.325 51.21)">
                  <rect width="38.44" height="4" />
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
      <div className="header__logo">
        <GatsbyLink to="/">Recode</GatsbyLink>
      </div>
      <div className="container">
        <nav className="header__nav">
          <ul>
            {navItems.map(item => (
              <li key={item.wordpress_id}>
                <Link
                  to={item.url.replace('#', '')}
                  smooth
                  spy
                  offset={-60}
                  style={{ cursor: 'pointer' }}
                >
                  {item.title}
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </div>
      <a className="header__cta" href="#" style={{ display: 'none' }}>
        Buy tickets
      </a>
    </header>
  );
};

Header.propTypes = {
  navItems: PropTypes.array.isRequired,
};

export default Header;
